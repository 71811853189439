import { render, staticRenderFns } from "./index.vue?vue&type=template&id=27ae4ff4&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ae4ff4",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WCoupons from '@/components/WCoupons.vue'
import WLoading from '@/components/WLoading.vue'
import WSelect from '@/components/WSelect.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WCoupons,WLoading,WSelect,WTopBar})
