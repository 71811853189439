<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div
        class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8"
      >
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="items.length === 0"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay cupones</div>
        </div>
        <div
          v-else
          class="overflow-hidden shadow bcoupon-b bcoupon-gray-200 sm:rounded-lg"
        >
          <table
            class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg"
          >
            <thead
              class="hidden md:table-header-group print:table-header-group"
            >
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Tipo
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Monto
                </th>
                <!-- <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:table-cell bg-gray-50"
                >
                  Fecha
                </th> -->
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Estado
                </th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden sm:hidden bg-gray-50"
                >
                  Procesado
                </th>
                <!-- <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:table-cell bg-gray-50"
                ></th> -->
              </tr>
            </thead>
            <tbody
              class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
            >
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="coupon in items"
                :key="coupon._id"
              >
                <td class="px-6 py-3 overflow-hidden whitespace-no-wrap">
                  <div class="text-sm">{{ coupon.name }}</div>
                  <div class="text-sm font-semibold">{{ coupon.code }}</div>
                </td>
                <td class="px-6 text-sm whitespace-no-wrap md:py-3">
                  <div class="" v-if="coupon.type == 'amount'">Monto Fijo</div>
                  <div class="" v-if="coupon.type == 'percentage'">
                    Porcentaje
                  </div>
                  <div class="" v-if="coupon.type == 'entradas'">
                    Entradas a evento
                  </div>
                </td>
                <td
                  class="hidden px-6 py-4 text-sm whitespace-no-wrap md:table-cell"
                  style="vertical-align: top"
                >
                  <div>
                    <w-price
                      v-if="coupon.type == 'amount'"
                      :value="Number(coupon.amount.ars)"
                    ></w-price>
                    <w-price
                      v-if="coupon.type == 'amount'"
                      currency="USD"
                      :value="Number(coupon.amount.usd)"
                    ></w-price>
                    <div v-if="coupon.type == 'percentage'">
                      {{ coupon.percentage }}%
                    </div>
                  </div>
                  <div class="text-xs">
                    <div v-if="coupon.method == 'wire'" class="text-gray-500">
                      Bancario
                    </div>
                    <div
                      v-if="coupon.method == 'mercadopago'"
                      class="text-gray-500"
                    >
                      Mercado Pago
                    </div>
                    <div v-if="coupon.method == 'paypal'" class="text-gray-500">
                      Paypal
                    </div>
                    <div v-if="coupon.method == 'stripe'" class="text-gray-500">
                      Stripe
                    </div>
                    <div v-if="coupon.method == 'free'" class="text-gray-500">
                      Gratis
                    </div>
                  </div>
                </td>
                <td
                  style="vertical-align: top"
                  class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap md:w-32"
                >
                  <div class="relative">
                    <v-popover offset="5" placement="auto" class="inline-block">
                      <span class="rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full px-2 py-1 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800"
                          id="options-menu"
                          aria-haspopup="menu"
                          aria-expanded="true"
                        >
                          Acciones
                          <!-- Heroicon name: chevron-down -->
                          <svg
                            class="w-5 h-5 ml-2 -mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>

                      <template slot="popover" class="p-0 m-0">
                        <div
                          class="p-1 py-1 text-xs text-left hover:bg-gray-100"
                        >
                          <router-link
                            class="flex items-center cursor-pointer"
                            :to="{
                              name: 'discounts-cupones-id',
                              params: {
                                id: coupon._id,
                              },
                            }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>

                            Editar cupon</router-link
                          >
                        </div>

                        <div
                          @click="remove(coupon._id)"
                          class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-red-500 hover:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>

                          Eliminar
                        </div>
                      </template>
                    </v-popover>
                  </div>
                </td>
              </tr>

              <!-- More rows... -->
            </tbody>
          </table>
          <div
            v-if="totalResults > limit"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar 20 más de {{ totalResults - limit }} resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
    };
  },
  methods: {
    remove(id) {
      this.$emit("remove", id);
    },
    async archive(coupon, status) {
      console.log("archive");
      this.busy = coupon._id;
      await this.$store.dispatch("food-coupons/patch", [
        coupon._id,
        { archive: status },
        {},
      ]);
      this.$emit("reload");
      this.busy = false;
    },
    nextPage() {
      this.limit += 20;
      this.$emit("next-page", this.limit);
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  bcoupon-bottom: 0;
}

th:not(:last-child) {
  bcoupon-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
