import { render, staticRenderFns } from "./WCoupons.vue?vue&type=template&id=53684b79&scoped=true&"
import script from "./WCoupons.vue?vue&type=script&lang=js&"
export * from "./WCoupons.vue?vue&type=script&lang=js&"
import style0 from "./WCoupons.vue?vue&type=style&index=0&id=53684b79&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53684b79",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WPrice from '@/components/WPrice.vue'
autoInstallComponents(component, {WPrice})
